import { useSession } from "next-auth/react"
import { useRouter } from "next-translate-routes"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios" // Ensure axios is installed: npm install axios

const AFMBanner = () => {
  const { data: session } = useSession()
  const router = useRouter()

  return (
    <Wrapper className="w-100">
      <div className="text-center">
        <img
          src="/components/AFM/AFM-Banner.png"
          className="w-100"
          alt="AFM Banner"
        />
      </div>
    </Wrapper>
  )
}

export default AFMBanner

const Wrapper = styled.div`
  .small {
    font-size: 11px;
    color: #666;
    margin-bottom: 0.5rem;
  }
`
