import { useSession } from "next-auth/react"
import React from "react"
import { Check } from "react-bootstrap-icons"
import styled from "styled-components"
import translate from "@/utils/translation"
import { useRouter } from "next-translate-routes"

const OnboardingBanner = () => {
  const { data: session } = useSession()

  if (!session?.email) {
    return <div className="p-1" />
  }

  const verification = session?.verification
  const translateText = {
    invest: translate("account_onboarding_complete_funds"),
    bank: translate("account_onboarding_add_bank"),
    finished: translate("account_onboarding_complete"),
    next: translate("account_onboarding_next"),
  }

  const isAffiliate = !!session?.affiliateId
  const stepOneStatus = isAffiliate ? "bank" : "invest"
  const stepOneLabel = isAffiliate ? translateText.bank : translateText.invest
  const stepTwoStatus = isAffiliate ? "invest" : "bank"
  const stepTwoLabel = isAffiliate ? translateText.invest : translateText.bank
  const investments = session?.investments?.filter(
    item => item.status === "committed"
  ).length
  const hasInvestments = investments > 0

  const normalizeOnboardStatus = status => {
    if (status === "profile" || status === "funds") {
      return "invest"
    }
    return status || "invest"
  }

  const currentStatus = () => {
    if (isAffiliate) {
      if (verification.status === "verified" && onboardStatus === "finished") {
        return 3
      } else if (
        verification.status === "verified" &&
        onboardStatus !== "finished"
      ) {
        return 2
      } else if (onboardStatus !== "invest") {
        return 2
      } else return 1
    } else {
      const isFullyVerified =
        hasInvestments &&
        ((verification.compliance_level === 200 && verification.bank) ||
          (verification.compliance_level === 400 &&
            verification.bank &&
            verification.identity))

      const isOverwrittenByOldSetup =
        hasInvestments &&
        verification.status === "verified" &&
        !verification.identity

      if (isFullyVerified || isOverwrittenByOldSetup) {
        return 3
      }
      if (hasInvestments) {
        return 2
      }
      return 1
    }
  }

  const getStepTitle = step => {
    if (isAffiliate) {
      switch (step) {
        case 1:
          return translateText.bank
        case 2:
          return translateText.invest
        case 3:
          return translateText.finished
        default:
          return ""
      }
    } else {
      switch (step) {
        case 1:
          return translateText.invest
        case 2:
          return translateText.bank
        case 3:
          return translateText.finished
        default:
          return ""
      }
    }
  }

  const getNextStepTitle = step => {
    if (isAffiliate) {
      switch (step) {
        case 1:
          return translateText.next + ": " + translateText.invest
        case 2:
          return translateText.next + ": " + translateText.finished
        default:
          return ""
      }
    } else {
      switch (step) {
        case 1:
          return translateText.next + ": " + translateText.bank
        case 2:
          return translateText.next + ": " + translateText.finished
        default:
          return ""
      }
    }
  }

  const normalizedStatus = normalizeOnboardStatus(session?.onboardStatus)
  const onboardStatus = normalizedStatus // Ensure onboardStatus is defined for affiliate path
  const currentStep = currentStatus()
  const totalSteps = 3
  const stepTitle = getStepTitle(currentStep)
  const nextStepTitle = getNextStepTitle(currentStep)

  const progressPercent = (currentStep / totalSteps) * 100

  const handleClick = () => {
    window.open(
      session?.verification?.compliance_level === 200 ||
        session?.verification?.compliance_level === 100
        ? session?.verification?.bank_url
        : session?.verification?.overview_url
        ? session?.verification?.overview_url
        : session?.verification?.external_id,
      "_blank"
    )
  }

  if (currentStep === 3) {
    return <div className="p-1" />
  }

  return (
    <Banner>
      {/* DESKTOP LAYOUT */}
      <div className="onboarding-banner d-none d-md-block">
        <div className="container">
          <div className="row">
            {/* STEP 1 */}
            <div className="step">
              {currentStep === 1 ? (
                <p
                  className="d-flex align-items-center"
                  style={{
                    fontWeight: 600,
                    cursor: stepOneStatus === "bank" ? "pointer" : "default",
                  }}
                  onClick={stepOneStatus === "bank" ? handleClick : undefined}
                >
                  <span className="me-3 circle">1</span>
                  {stepOneLabel}
                </p>
              ) : (
                <p className="d-flex align-items-center">
                  <Check className="me-3 finished" size="14px" />
                  <span className="line-through">{stepOneLabel}</span>
                </p>
              )}
            </div>
            {/* STEP 2 */}
            <div className="step">
              {currentStep === 2 ? (
                <p
                  className="d-flex align-items-center"
                  style={{
                    fontWeight: 600,
                    cursor: stepTwoStatus === "bank" ? "pointer" : "default",
                  }}
                  onClick={stepTwoStatus === "bank" ? handleClick : undefined}
                >
                  <span className="me-3 circle">2</span>
                  {stepTwoLabel}
                </p>
              ) : currentStep > 2 ? (
                <p className="d-flex align-items-center">
                  <Check className="me-3 finished" size="14px" />
                  <span className="line-through">{stepTwoLabel}</span>
                </p>
              ) : (
                <p className="d-flex align-items-center">
                  <span className="me-3 circle">2</span>
                  {stepTwoLabel}
                </p>
              )}
            </div>
            {/* STEP 3 */}
            <div className="step">
              <p
                className="d-flex align-items-center"
                style={{
                  fontWeight: currentStep === 3 ? 600 : "normal",
                }}
              >
                {currentStep === 3 ? (
                  <Check className="me-3 finished" size="14px" />
                ) : (
                  <span className="me-3 circle">3</span>
                )}
                {translateText.finished}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* MOBILE LAYOUT */}
      <div className="onboarding-banner-mobile d-block d-md-none">
        <div className="container">
          <div
            className={
              currentStep === 2 ? "clickable mobile-row" : "mobile-row"
            }
            onClick={() => {
              if (currentStep === 2 && stepTwoStatus === "bank") {
                window.open(
                  session?.verification?.compliance_level === 400
                    ? session?.verification?.overview_url
                    : session?.verification?.bank_url
                    ? session?.verification?.bank_url
                    : session?.verification?.external_id,
                  "_blank"
                )
              }
            }}
          >
            <div className="circle mt-1">
              <svg width="40" height="40" viewBox="0 0 36 36">
                <path
                  className="circle-bg"
                  d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                  className="circle-progress"
                  strokeDasharray={`${progressPercent}, 100`}
                  d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text
                  x="18"
                  y="22.35"
                  textAnchor="middle"
                  className="percentage-text"
                  transform="rotate(90,18,18)"
                >
                  {`${currentStep}/${totalSteps}`}
                </text>
              </svg>
            </div>
            <div className="step-info ms-3">
              <div className="current-step">{stepTitle}</div>
              {nextStepTitle && (
                <div className="next-step">{nextStepTitle}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Banner>
  )
}

export default OnboardingBanner

// STYLES remain unchanged
const Banner = styled.div`
  background-color: #fb8501;
  padding: 10px 0;
  margin-top: 40px;
  margin-bottom: 1rem;

  .onboarding-banner {
    .container {
      .row {
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;

        .step {
          flex: 1 1 30%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 10px 5px;

          p {
            margin: 0;
            color: #fff;
            font-size: 0.875rem;
            text-align: center;
          }

          .circle {
            color: white;
            border: 1px solid #fff;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.75rem;
          }

          .finished {
            background: white;
            border: 1px solid #fff;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.75rem;
            color: #fb8501;
          }
          .line-through {
            text-decoration: line-through;
          }
        }
      }
    }
  }

  .onboarding-banner-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    margin-top: 5px;

    .onboarding-banner {
      display: none;
    }

    .onboarding-banner-mobile {
      display: block;

      .container {
        max-width: 1000px;
        margin: 0 auto;

        .mobile-row {
          display: flex;
          align-items: center;
          padding: 0 10px;

          .circle {
            position: relative;
            width: 40px;
            height: 40px;
          }
          .circle svg {
            transform: rotate(-90deg);
          }
          .circle-bg {
            fill: none;
            stroke: #fff;
            stroke-opacity: 0.2;
            stroke-width: 3;
          }
          .circle-progress {
            fill: none;
            stroke: #fff;
            stroke-width: 3;
            stroke-linecap: round;
          }
          .percentage-text {
            fill: #fff;
            font-size: 0.75rem;
            font-weight: 600;
          }

          .step-info {
            .current-step {
              font-size: 1rem;
              font-weight: 600;
              color: #fff;
              margin-bottom: 2px;
            }
            .next-step {
              font-size: 0.75rem;
              color: #fff;
            }
          }
        }
      }
    }
  }
`
