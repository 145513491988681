import React, { useContext } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Typography,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import styled from "styled-components"
import { X } from "react-bootstrap-icons"
import { Context } from "@/context/state"
import moment from "moment"
import CurrencyFormat from "react-currency-format"
import translate from "@/utils/translation"
import { useRouter } from "next-translate-routes"

import "moment/locale/de"
import "moment/locale/nl"
import "moment/locale/en-gb"
import "moment/locale/es"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Investors = ({ open, setOpen }) => {
  const { state } = useContext(Context)
  const router = useRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  if (!state.listing?.financials?.lastInvestors) return null

  const latestInvestments = state.listing.financials.lastInvestors
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(0, 8)

  const handleClose = () => setOpen(false)

  moment.locale(router?.locale || "en")

  const formatDate = dateString => moment(dateString).format("ll")

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      TransitionComponent={isMobile ? Transition : undefined}
      keepMounted
    >
      <DialogTitle>
        <Typography variant="h6" component="div">
          {translate("investments_latest")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <X size="25" className="close" onClick={handleClose} />
        <InvestmentBox>
          <List>
            {latestInvestments.map((investor, index) => (
              <StyledListItem key={index}>
                <LeftSection>
                  <Name>
                    {investor.firstName ||
                      translate("listing_single_tabs_investors_anonymous")}
                  </Name>
                  <DateText>{formatDate(investor.createdAt)}</DateText>
                </LeftSection>
                <Amount>
                  <CurrencyFormat
                    value={investor.investmentAmount}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={" €"}
                  />
                </Amount>
              </StyledListItem>
            ))}
          </List>
        </InvestmentBox>
      </DialogContent>
    </StyledDialog>
  )
}

export default Investors
const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 480px;
    margin: 0 auto;

    @media (max-width: 600px) {
      width: 100vw;
      max-height: 90vh;
      margin: 0;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 10px 10px 0 0;
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
`

const InvestmentBox = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0rem;
`

const StyledListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
`

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
`

const Name = styled.span`
  font-size: 1rem;
  font-weight: 600;
`

const DateText = styled.span`
  font-size: 0.85rem;
  color: #888;
  margin-top: 2px;
`

const Amount = styled.span`
  font-size: 0.875rem;
  color: #333;
  font-weight: 600;
`
